// Generated by Framer (c60b0a0)

import { addFonts, addPropertyControls, ComponentViewportProvider, ControlType, cx, CycleVariantState, getFonts, ResolveLinks, useComponentViewport, useLocaleInfo, useRouter, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import ButtonsTabButton from "https://framerusercontent.com/modules/BJAxc9n1UJZ3iaMdUgvw/lOLYy8HgA0Kx0YEypa4X/OnqS0INRr.js";
import * as localizedValues from "./UN8XEA0bO-0.js";
const ButtonsTabButtonFonts = getFonts(ButtonsTabButton);

const cycleOrder = ["yicYJpAx2", "WN6F8z63N", "dEub9VHyC", "JO6PISqde"];

const serializationHash = "framer-2vEmS"

const variantClassNames = {dEub9VHyC: "framer-v-1w6j19o", JO6PISqde: "framer-v-jdgbhe", WN6F8z63N: "framer-v-1w2e4ho", yicYJpAx2: "framer-v-1mwfknx"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const valuesByLocaleId = {ZULQJWnvF: localizedValues}

const getLocalizedValue = (key, locale) => {
while (locale) {
const values = valuesByLocaleId[locale.id]
if (values) {
const value = values[key]
if (value) {
return value
}
}
locale = locale.fallback
}
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const humanReadableVariantMap = {"Bug Fix": "WN6F8z63N", All: "yicYJpAx2", Feature: "dEub9VHyC", Improvement: "JO6PISqde"}

const getProps = ({height, id, width, ...props}) => { return {...props, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "yicYJpAx2"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "yicYJpAx2", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const router = useRouter()

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.nav {...restProps} {...gestureHandlers} className={cx(serializationHash, ...sharedStyleClassNames, "framer-1mwfknx", className, classNames)} data-framer-name={"All"} layoutDependency={layoutDependency} layoutId={"yicYJpAx2"} ref={ref ?? ref1} style={{...style}} {...addPropertyOverrides({dEub9VHyC: {"data-framer-name": "Feature"}, JO6PISqde: {"data-framer-name": "Improvement"}, WN6F8z63N: {"data-framer-name": "Bug Fix"}}, baseVariant, gestureVariant)}><ResolveLinks links={[{href: {webPageId: "Wd43Qu0is"}, implicitPathVariables: undefined}, {href: {webPageId: "Wd43Qu0is"}, implicitPathVariables: undefined}, {href: {webPageId: "Wd43Qu0is"}, implicitPathVariables: undefined}, {href: {webPageId: "Wd43Qu0is"}, implicitPathVariables: undefined}]}>{(resolvedLinks) => (
<ComponentViewportProvider height={40}><motion.div className={"framer-1hmbwsg-container"} data-framer-name={"All"} layoutDependency={layoutDependency} layoutId={"qWsIyhfv4-container"} name={"All"}><ButtonsTabButton ftepsXxMk={resolvedLinks[0]} height={"100%"} id={"qWsIyhfv4"} layoutId={"qWsIyhfv4"} name={"All"} style={{height: "100%"}} variant={"nIoldp4_r"} width={"100%"} xiNBRR5p1={"All"} {...addPropertyOverrides({dEub9VHyC: {ftepsXxMk: resolvedLinks[2], variant: "GrOSj_kyO"}, JO6PISqde: {ftepsXxMk: resolvedLinks[3], variant: "GrOSj_kyO"}, WN6F8z63N: {ftepsXxMk: resolvedLinks[1], variant: "GrOSj_kyO"}}, baseVariant, gestureVariant)}/></motion.div></ComponentViewportProvider>
)}</ResolveLinks><ResolveLinks links={[{href: {webPageId: "c9VW_E2LD"}, implicitPathVariables: undefined}, {href: {webPageId: "c9VW_E2LD"}, implicitPathVariables: undefined}, {href: {webPageId: "c9VW_E2LD"}, implicitPathVariables: undefined}, {href: {webPageId: "c9VW_E2LD"}, implicitPathVariables: undefined}]}>{(resolvedLinks1) => (
<ComponentViewportProvider height={40}><motion.div className={"framer-ax7sdq-container"} data-framer-name={"Bug Fix"} layoutDependency={layoutDependency} layoutId={"fFVk6OZUE-container"} name={"Bug Fix"}><ButtonsTabButton ftepsXxMk={resolvedLinks1[0]} height={"100%"} id={"fFVk6OZUE"} layoutId={"fFVk6OZUE"} name={"Bug Fix"} style={{height: "100%"}} variant={"GrOSj_kyO"} width={"100%"} xiNBRR5p1={"Bug fix"} {...addPropertyOverrides({dEub9VHyC: {ftepsXxMk: resolvedLinks1[2]}, JO6PISqde: {ftepsXxMk: resolvedLinks1[3]}, WN6F8z63N: {ftepsXxMk: resolvedLinks1[1], variant: "nIoldp4_r"}}, baseVariant, gestureVariant)}/></motion.div></ComponentViewportProvider>
)}</ResolveLinks><ResolveLinks links={[{href: {webPageId: "ZGEiBQZHg"}, implicitPathVariables: undefined}, {href: {webPageId: "ZGEiBQZHg"}, implicitPathVariables: undefined}, {href: {webPageId: "ZGEiBQZHg"}, implicitPathVariables: undefined}, {href: {webPageId: "ZGEiBQZHg"}, implicitPathVariables: undefined}]}>{(resolvedLinks2) => (
<ComponentViewportProvider height={40}><motion.div className={"framer-bamk8v-container"} data-framer-name={"Feature"} layoutDependency={layoutDependency} layoutId={"f4MaFzv7y-container"} name={"Feature"}><ButtonsTabButton ftepsXxMk={resolvedLinks2[0]} height={"100%"} id={"f4MaFzv7y"} layoutId={"f4MaFzv7y"} name={"Feature"} style={{height: "100%"}} variant={"GrOSj_kyO"} width={"100%"} xiNBRR5p1={"Feature"} {...addPropertyOverrides({dEub9VHyC: {ftepsXxMk: resolvedLinks2[2], variant: "nIoldp4_r"}, JO6PISqde: {ftepsXxMk: resolvedLinks2[3]}, WN6F8z63N: {ftepsXxMk: resolvedLinks2[1]}}, baseVariant, gestureVariant)}/></motion.div></ComponentViewportProvider>
)}</ResolveLinks><ResolveLinks links={[{href: {webPageId: "DpFVyldq6"}, implicitPathVariables: undefined}, {href: {webPageId: "DpFVyldq6"}, implicitPathVariables: undefined}, {href: {webPageId: "DpFVyldq6"}, implicitPathVariables: undefined}, {href: {webPageId: "DpFVyldq6"}, implicitPathVariables: undefined}]}>{(resolvedLinks3) => (
<ComponentViewportProvider height={40}><motion.div className={"framer-hrlhyx-container"} data-framer-name={"Improvement"} layoutDependency={layoutDependency} layoutId={"fl_pXWL2F-container"} name={"Improvement"}><ButtonsTabButton ftepsXxMk={resolvedLinks3[0]} height={"100%"} id={"fl_pXWL2F"} layoutId={"fl_pXWL2F"} name={"Improvement"} style={{height: "100%"}} variant={"GrOSj_kyO"} width={"100%"} xiNBRR5p1={"Improvement"} {...addPropertyOverrides({dEub9VHyC: {ftepsXxMk: resolvedLinks3[2]}, JO6PISqde: {ftepsXxMk: resolvedLinks3[3], variant: "nIoldp4_r"}, WN6F8z63N: {ftepsXxMk: resolvedLinks3[1]}}, baseVariant, gestureVariant)}/></motion.div></ComponentViewportProvider>
)}</ResolveLinks></motion.nav></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-2vEmS.framer-miav3j, .framer-2vEmS .framer-miav3j { display: block; }", ".framer-2vEmS.framer-1mwfknx { align-content: flex-start; align-items: flex-start; display: flex; flex-direction: row; flex-wrap: wrap; gap: 12px; height: min-content; justify-content: flex-start; overflow: hidden; padding: 0px; position: relative; width: 541px; }", ".framer-2vEmS .framer-1hmbwsg-container, .framer-2vEmS .framer-ax7sdq-container, .framer-2vEmS .framer-bamk8v-container, .framer-2vEmS .framer-hrlhyx-container { flex: none; height: 40px; position: relative; width: auto; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-2vEmS.framer-1mwfknx { gap: 0px; } .framer-2vEmS.framer-1mwfknx > * { margin: 0px; margin-left: calc(12px / 2); margin-right: calc(12px / 2); } .framer-2vEmS.framer-1mwfknx > :first-child { margin-left: 0px; } .framer-2vEmS.framer-1mwfknx > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 40
 * @framerIntrinsicWidth 541
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","auto"]},"WN6F8z63N":{"layout":["fixed","auto"]},"dEub9VHyC":{"layout":["fixed","auto"]},"JO6PISqde":{"layout":["fixed","auto"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerUN8XEA0bO: React.ComponentType<Props> = withCSS(Component, css, "framer-2vEmS") as typeof Component;
export default FramerUN8XEA0bO;

FramerUN8XEA0bO.displayName = "Tabs/Changelog Tabs";

FramerUN8XEA0bO.defaultProps = {height: 40, width: 541};

addPropertyControls(FramerUN8XEA0bO, {variant: {options: ["yicYJpAx2", "WN6F8z63N", "dEub9VHyC", "JO6PISqde"], optionTitles: ["All", "Bug Fix", "Feature", "Improvement"], title: "Variant", type: ControlType.Enum}} as any)

addFonts(FramerUN8XEA0bO, [{explicitInter: true, fonts: []}, ...ButtonsTabButtonFonts], {supportsExplicitInterCodegen: true})